import * as React from "react";
import { css } from "react-emotion";
import { graphql } from "gatsby";
import day from "dayjs";

import { Layout } from "../components/layout";
import { rhythm } from "../utils/typography";
import { PodcastPreview } from "../components/PodcastPreview";

export default ({ data }: any) => {
  const currentDate = day();

  return (
    <Layout>
      <div>
        <h5
          style={{
            fontWeight: 400,
            marginBottom: rhythm(1 / 4),
            letterSpacing: 2.38
          }}
        >
          SAFFRON
        </h5>
        <h1
          className={css`
            font-size: 54px;
            margin-top: 0;
            display: inline-block;
            border-bottom: 1px solid;
            width: 100%;
            padding-bottom: ${rhythm(3 / 8)};
          `}
        >
          Behind the Scenes
        </h1>
      </div>
      <div>
        {data.allPagesYaml.edges.map(
          ({ node }: any) =>
            currentDate > day(node.date) ? (
              <PodcastPreview node={node} key={node.id} />
            ) : null
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allPagesYaml(sort: { fields: [episodeNumber], order: DESC }) {
      edges {
        node {
          id
          fields {
            slug
            shortDescription
          }
          episodeNumber
          time
          title
          date
        }
      }
    }
  }
`;
